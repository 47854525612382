import Vue from 'vue'
import Clipboard from 'clipboard'
import Toast from '../components/common/Toast/index'

function getStyle (el, name) {
  return getComputedStyle(el, null)[name]
}

export const auto = Vue.directive('auto', {
  update: function (el, binding) {
    el.style.resize = 'none'
    // 设置输入高度
    const minHeight = binding.value
    const style = el.style
    const padding = parseInt(getStyle(el, 'paddingTop')) + parseInt(getStyle(el, 'paddingBottom'))
    let height = 0
    let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    el.style.height = minHeight + 'px'

    if (el.scrollHeight > minHeight) {
      height = el.scrollHeight - padding
      style.overflowY = 'hidden'

      style.height = height + 'px'
      scrollTop += parseInt(style.height) - el.currHeight
      document.body.scrollTop = scrollTop
      el.currHeight = parseInt(style.height)
    }
  }
})

export const copy = Vue.directive('copy', {
  bind: function (el, binding) {
    el.clipboard = new Clipboard(el, {
      text () {
        return binding.value
      }
    })
    el.clipboard.on('success', function (e) {
      Toast({
        message: '文本已粘貼至剪貼板',
        type: 'success'
      })
    })
  },
  unbind: function (el) {
    el.clipboard.destroy()
  }
})

<template>
  <section class="group">
    <header>
      <h2>每個人都是生活的作者</h2>
    </header>
    <div class="container text-center">
      <template v-for="(item, index) in groupList">
        <div class="group-item row" :key="index">
          <div class="group-item-hero col" v-lazy:background-image="item.banner">
            <div class="holder" />
          </div>
          <div class="group-item-intro col-sm-4">
            <div class="section-title" :style="{backgroundColor: item.theme}">
              {{item.title}}
            </div>
            <div class="desc" :style="{color: item.theme}">
              {{item.desc}}
            </div>
            <router-link to="/products?type=diarybook" class="text-primary">
              開始製作
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import Mama from '../../../static/images/home/group-mother.png'
import Lovers from '../../../static/images/home/group-lovers.png'
import Designer from '../../../static/images/home/group-desginer.png'
import Life from '../../../static/images/home/group-lifeman.png'
export default {
  name: '',
  data: () => ({
    groupList: [
      {
        theme: 'rgb(200, 150, 102)',
        banner: Mama,
        title: '媽咪們',
        desc: '隨手發在臉書的寶寶日常照片和趣事，簡單導出就是一本成長日記。'
      },
      {
        theme: 'rgb(229, 126, 130)',
        banner: Lovers,
        title: '情侶們',
        desc: '沒有什麽比你們發在臉書裏共同的回憶，更值得用書這種介質珍藏起來了。'
      },
      {
        theme: 'rgb(80, 131, 150)',
        banner: Designer,
        title: '旅行者',
        desc: '你在各地旅行時發在臉書的美景見聞，就是妳的旅行日記。'
      },
      {
        theme: 'rgb(112, 150, 104)',
        banner: Life,
        title: '生活家',
        desc: '每個熱愛生活的人都該有屬於自己的生活手冊，IPastBook就是這本手冊。'
      }
    ]
  }),
  computed: {},
  created () {},
  methods: {}
}
</script>

<style lang="scss">
.group {
  &-item + &-item {
    margin-top: 2rem;
  }
  &-item {
    border-radius: 4px;
    background-color: #f7f7f7;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
    font-size: 1.15rem;
    overflow: hidden;
    &:nth-child(even) {
      .group-item-hero {
        order: 1;
      }
    }
    &.row {
      display: flex;
      align-items: center;
      .col {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;
      }
      .col-sm-4 {
        width: 33.33333%;
        text-align: left;
      }
    }
    &-hero {
      background: {
        image: url(../../../static/images/home/group-mother.png);
        repeat: no-repeat;
        size: cover;
      }
      .holder {
        width: 100%;
        padding-bottom: 50%;
      }
    }
    &-intro {
      padding: 1.5rem!important;
      .desc {
        margin-bottom: .5rem;
      }
      a {
        text-decoration: underline;
      }
    }
    .section-title {
      display: inline-block;
      color: #fff;
      margin-bottom: .5em;
      padding: .25rem 1rem;
      border-radius: 100px;
      background-color: darkgreen;
    }
  }
  .container {
    padding: 0 6rem!important;
  }
}
</style>

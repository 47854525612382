const payment = [
  {
    path: '/app/payment/',
    name: 'payment',
    component: (resolve) => {
      require(['./Payment.vue'], resolve)
    },
    meta: {
      title: 'title.checkout',
      root: 'payment'
    },
    children: [
      {
        path: 'check',
        name: 'check',
        component: (resolve) => {
          require(['./Check/Check.vue'], resolve)
        },
        meta: {
          title: 'title.checkout',
          step: 1,
          root: 'payment',
          backName: (status) => status ? 'review' : 'preview', // 书籍是否定稿，返回不同预览
          nextName: 'address',
          savedPosition: true
        }
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: (resolve) => {
          require(['./Checkout/Checkout.vue'], resolve)
        },
        meta: {
          title: 'title.checkout',
          step: 1,
          root: 'payment',
          backName: (status) => status ? 'review' : 'preview', // 书籍是否定稿，返回不同预览
          nextName: 'address',
          savedPosition: true
        }
      },
      {
        path: 'address',
        name: 'address',
        component: (resolve) => {
          require(['./Address/Address.vue'], resolve)
        },
        meta: {
          title: 'title.address',
          step: 2,
          backName: 'checkout',
          nextName: 'trade',
          root: 'payment'
        }
      },
      {
        path: 'trade',
        name: 'trade',
        component: (resolve) => {
          require(['./Trade/Trade.vue'], resolve)
        },
        meta: {
          title: 'title.checkout',
          step: 3,
          backName: 'address',
          root: 'payment'
        }
      }
    ]
  },
  {
    path: '/app/payment/:bid/status',
    name: 'payment-status',
    component: (resolve) => {
      require(['./Status.vue'], resolve)
    }
  },
  {
    path: '/app/checkout',
    name: 'order-check',
    component: (resolve) => {
      require(['./Check/Check.vue'], resolve)
    },
    meta: {
      savedPosition: true
    }
  },
  {
    path: '/app/trade/:bid',
    name: 'order-trade',
    component: (resolve) => {
      require(['./Pay/Pay.vue'], resolve)
    }
  },
  {
    path: '/app/addresses',
    name: 'order-address',
    component: (resolve) => {
      require(['./Address/Addresses.vue'], resolve)
    }
  }
]

export default payment

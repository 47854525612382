import Vue from 'vue'
import Vuex from 'vuex'
import * as types from './mutation-types'
import Cookies from 'js-cookie'
import moment from 'moment'

import TW from '../../static/json/zh-tw.json'
import HK from '../../static/json/zh-hk.json'
import EN from '../../static/json/en.json'

import currencyConfig from '../../static/json/country.json'

Vue.use(Vuex)

const validLanguages = ['zh-tw', 'zh-hk', 'en']
const validLocales = {
  'zh-tw': 'TW',
  'zh-hk': 'HK',
  en: 'US'
}
let language = Cookies.getJSON('m_language') || window.navigator.language.toLowerCase()
if (validLanguages.indexOf(language) === -1) {
  language = validLanguages[0]
}

document.documentElement.lang = language

export const state = {
  user: (window.isTest ? Cookies.getJSON('test_user') : Cookies.getJSON('m_user')) || {},
  loading: {
    visible: false,
    text: 'Loading...'
  },
  loginShow: false,
  locale: Cookies.get('m_locale') || validLocales[language] || 'TW', // 地区
  language: language, // 语言,
  currencyConfig,
  messages: {
    en: EN,
    'zh-tw': TW,
    'zh-hk': HK
  },
  showStep: true,
  promotionConf: {},
  promotionImages: {},
  promotionLoaded: false,
  availableAmount: null
}

const getters = {
  user: s => s.user,
  loading: s => s.loading,
  loadingState: s => s.loading.visible,
  loginShow: s => s.loginShow,
  locale: s => s.locale,
  language: s => s.language,
  messages: s => s.messages,
  showStep: s => s.showStep,
  promotionConf: s => s.promotionConf,
  promotionImages: s => s.promotionImages,
  promotionLoaded: s => s.promotionLoaded,
  availableAmount: s => s.availableAmount,
  currencyConfig: s => s.currencyConfig
}

const actions = {
  setUser ({ commit }, user) {
    commit(types.SET_USER, user)
  },
  setLoading ({ commit }, loading) {
    let visible = false
    let text = 'Loading...'
    if (typeof loading === 'boolean') {
      visible = loading
    } else {
      visible = loading.visible
      text = loading.text || text
    }
    commit(types.SET_LOADING, { visible, text })
  },
  setLoginShow ({ commit }, loginShow) {
    commit(types.SET_LOGIN_SHOW, loginShow)
  },
  setLocale ({ commit }, locale) {
    moment.locale(locale)
    commit(types.SET_LOCALE, locale)
  },
  setLanguage ({ commit }, language) {
    commit(types.SET_LANGUAGE, language)
    document.documentElement.lang = language
  },
  setMessages ({ commit }, messages) {
    commit(types.SET_MESSAGES, messages)
  },
  setStep ({ commit }, showStep) {
    commit(types.SET_STEP_SHOW, showStep)
  },
  setPromotionConf ({ commit }, config) {
    commit(types.SET_PROMOTION_CONF, config)
  },
  setPromotionImages ({ commit }, images) {
    commit(types.SET_PROMOTION_IMAGES, images)
  },
  setPromotionLoaded ({ commit }, loaded) {
    commit(types.SET_PROMOTION_LOADED, loaded)
  },
  setAvailableAmount ({ commit }, amount) {
    commit(types.SET_AVAILABLE_AMOUNT, amount)
  }
}

const mutations = {
  [types.SET_USER] (state, user) {
    state.user = user
    let expires = 1
    if (user.token && user.token.expiredAt) {
      expires = new Date(user.token.expiredAt)
    }
    if (window.isTest) {
      if (/localhost/gi.test(window.location.hostname)) {
        Cookies.set('test_user', user, { expires })
      } else {
        Cookies.set('test_user', user, { expires, domain: '.ipastbook.com' })
      }
    } else {
      Cookies.set('m_user', user, { expires, domain: '.ipastbook.com' })
    }
  },
  [types.SET_LOADING] (state, { visible, text }) {
    state.loading.visible = visible
    state.loading.text = text
  },
  [types.SET_LOGIN_SHOW] (state, loginShow) {
    state.loginShow = loginShow
  },
  [types.SET_LOCALE] (state, locale) {
    if (!locale) {
      return
    }
    state.locale = locale
    Cookies.set('m_locale', locale)
  },
  [types.SET_LANGUAGE] (state, language) {
    state.language = language
    Cookies.set('m_language', language)
  },
  [types.SET_MESSAGES] (state, messages) {
    state.messages = messages
  },
  [types.SET_STEP_SHOW] (state, showStep) {
    state.showStep = showStep
  },
  [types.SET_PROMOTION_CONF] (state, promotionConf) {
    state.promotionConf = promotionConf
  },
  [types.SET_PROMOTION_IMAGES] (state, promotionImages) {
    state.promotionImages = promotionImages
  },
  [types.SET_PROMOTION_LOADED] (state, loaded) {
    state.promotionLoaded = loaded
  },
  [types.SET_AVAILABLE_AMOUNT] (state, amount) {
    state.availableAmount = amount
  }
}

const store = new Vuex.Store({
  state, getters, actions, mutations
})

export default store

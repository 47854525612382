<template>
  <div class="i-modal">
    <transition name="modal" @after-leave="onAfterLeave">
      <div class="modal-mask" @click="_close" v-show="show"></div>
    </transition>
    <transition name="modal">
      <div class="modal-content" @click.stop v-show="show">
        <div class="modal-body">
          <h3 class="title text-center" v-if="$slots.title || title">
            <slot name="title">{{title}}</slot>
          </h3>
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" :class="{'dialog': dialog}">
          <slot name="footer" v-if="$slots.footer"></slot>
          <div class="modal-footer-function" v-if="dialog">
            <button @click="cancelClicked">
              <b v-if="cancel" :class="cancelClass">{{cancelText || '取消'}}</b>
              <b v-else :class="['text-primary', okClass]">{{okText || '确认'}}</b>
            </button>
            <button v-if="cancel" :class="['text-primary', okClass]" @click="okClicked">
              <b :class="okClass">{{okText || '确认'}}</b>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: String,
    dialog: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: Boolean,
      default: true
    },
    onCancel: {
      type: Function,
      default: function () {
        return function () {
        }
      }
    },
    onOk: {
      type: Function,
      default: function () {
        return function () {
        }
      }
    },
    handleAfterLeave: {
      type: Function,
      default: function () {
        return function () {
        }
      }
    },
    okText: {
      type: String,
      default: ''
    },
    cancelText: {
      type: String,
      default: ''
    },
    okClass: {
      type: String,
      default: ''
    },
    cancelClass: {
      type: String,
      default: ''
    }
  },
  methods: {
    _close () {
      this.$emit('close')
    },
    okClicked () {
      this.$emit('ok')
      this.onOk(this)
    },
    cancelClicked () {
      this.$emit('cancel')
      this.onCancel(this)
    },
    // 为了暴露transition结束的钩子
    onAfterLeave () {
      this.handleAfterLeave(this.$el)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter-active, .modal-leave-active {
  transition: all 3s ease;
}

.modal-enter, .modal-leave-to /* .fade-leave-active in below version 2.1.8 */
{
  transform: scale(1.1);
  transform-origin: center center;
}

.modal-mask, .modal-wrapper {
  position: fixed;
  z-index: 1003;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
}

.modal-mask {
  background-color: rgba(0, 0, 0, .5);
  transition: opacity .3s ease;
}

.modal-content {
  overflow: hidden;
  backdrop-filter: blur(10px) brightness(1.4);
  background-color: rgba(255, 255, 255, .95);
  position: fixed;
  top: 15%;
  left: 0;
  right: 0;
  max-width: 600px;
  max-height: 70%;
  margin: 0 auto;
  z-index: 2500;
  transition: all .3s;
  box-shadow: $dp-4;
  border-radius: 4px;
  @media (max-width: 600px) {
    width: 80%;
  }
}

.modal-body {
  position: relative;
  overflow-y: auto;
  max-height: 60vh;
  padding: 1rem;
  background-color: #fff;

  .title {
    font-size: 18px;
    line-height: 110%;
    margin: .6rem 0 .45rem;
    font-weight: 600;
  }

  .content {
    font-size: 14px;
  }
}

.modal-footer {
  padding: 10px 1rem;

  &.dialog {
    border-top: 1px solid $el-color-gray;
  }

  .modal-footer-function {
    height: 36px;
    line-height: 36px;
    overflow: hidden;
    display: flex;

    button {
      height: 36px;
      line-height: inherit;
      flex: 1;
      float: left;
      cursor: pointer;
    }

    > button + button {
      border-left: 1px solid $el-color-gray;
    }
  }
}
</style>

const config = {
  appTheme: '#41c1ad',
  baseURL: 'https://ilifediary.com',
  betaURL: 'https://beta.ilifediary.com',
  // cover image's baseURL
  coverImageURL: 'https://canvas.xinshu.me/generate/',
  // book cover's name
  coverName: {
    'type-1': 'cover-psb-1',
    'type-1-white': 'cover-psb-1-white',
    'type-2': 'cover-psb-2',
    'type-2-white': 'cover-psb-2-white',
    'type-3': 'cover-psb-3',
    'type-3-white': 'cover-psb-3-white',
    'type-4': 'cover-psb-4',
    'type-4-white': 'cover-psb-4-white',
    'type-5': 'cover-psb-5',
    'type-5-white': 'cover-psb-5-white',
    'type-6': 'cover-psb-6',
    'type-7': 'cover-psb-7',
    'type-8': 'cover-psb-8',
    'type-9': 'cover-psb-9',
    'type-10': 'cover-psb-10'
  },
  colorfulCovers: /type-[1-5]$/,
  plainCover: ['type-6', 'type-7', 'type-8', 'type-9', 'type-10', 'type-11', 'cover-magazine-2', 'cover-magazine-1'],
  months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  // 装帧
  bindings: [
    { name: 'paperback', desc: 'book.bindingPaperback', default: true },
    { name: 'hardback', desc: 'book.bindingHardback', default: true },
    { name: 'pdf', desc: 'book.bindingPdf', default: true }
  ],
  // dark theme cover
  darkTheme: ['#434343', '#c44a5c', '#ffc73b', '#43a3aa', '#82b98d'],
  // replace cover
  replaceColor: { '#cbedff': '#bbe6f9', '#c0eeb2': '#b5d8a9', '#f09907': '#ffc73b', '#4293ab': '#43a3aa' },
  // all cover color
  themeList: ['#ffffff', '#f5c4c3', '#fee089', '#bbe6f9', '#b5d8a9', '#434343', '#c44a5c', '#ffc73b', '#43a3aa', '#82b98d'],
  templates: [// 描述文字替换为关键字
    { name: 'a5-1', desc: 'book.typesetWaterfall' },
    { name: 'a5-2', desc: 'book.typesetColumn' },
    { name: 'a4-1', desc: 'book.typesetWaterfallA4' },
    { name: 'a4-2', desc: 'A4 童趣貼圖版' },
    { name: 'a5-waterfall-tweet', desc: 'book.typesetWaterfall' },
    { name: 'a5-column-tweet', desc: 'book.typesetColumn' },
    { name: 'a5-waterfall-blog', desc: 'book.typesetBlog' }
  ],
  minBookPagesLimitation: 60,
  groupConf: [
    { num: 0, discount: 15 },
    { num: 2, discount: 20 },
    { num: 3, discount: 25 },
    { num: 5, discount: 30 },
    { num: 10, discount: 40 }
  ]
}

export default config

export default {
  methods: {
    FBInvite (uid) {
      this.checkoutFB().then(data => {
        window.FB.ui({
          method: 'share',
          href: this.$store.state.language === 'jp' ? `https://ipastbook.com/jp/?sourceId=${uid}` : `${window.location.origin}/?sourceId=${uid}`,
          hashtag: this.$store.state.language === 'jp' ? '#iPastBook JP' : '#iPastBook'
        }, (res) => {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'ShareHosts',
            eventAction: 'share our host to friends',
            eventLabel: 'share button'
          })
        })
      })
    },
    FBShareBook (bid) {
      this.checkoutFB().then(data => {
        window.FB.ui({
          method: 'share',
          href: `${window.location.origin}/book/?bid=${bid}`,
          hashtag: this.$store.state.language === 'jp' ? '#iPastBook JP' : '#iPastBook'
        }, (res) => {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'ShareBooks',
            eventAction: 'share books',
            eventLabel: 'share button'
          })
        })
      })
    },
    FBShareGroup (gid) {
      this.checkoutFB().then(data => {
        window.FB.ui({
          method: 'share',
          href: `${window.location.origin}/group/start/?gid=${gid}`,
          hashtag: this.$store.state.language === 'jp' ? '#iPastBook JP' : '#iPastBook'
        }, (res) => {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'ShareGroup',
            eventAction: 'share group',
            eventLabel: 'share button'
          })
        })
      })
    },
    FBShareGroups (ref) {
      this.checkoutFB().then(data => {
        window.FB.ui({
          method: 'share',
          href: `${window.location.origin}/groups?ref=${ref}`,
          hashtag: this.$store.state.language === 'jp' ? '#iPastBook JP' : '#iPastBook'
        }, (res) => {
          window.ga('send', {
            hitType: 'event',
            eventCategory: 'ShareOfficialGroups',
            eventAction: 'share official group',
            eventLabel: 'share button'
          })
        })
      })
    },
    checkoutFB () {
      return new Promise((resolve, reject) => {
        if (!window.FB) {
          this.$toast({
            message: this.$t('invitation.tipInvitationFailed'),
            position: 'bottom'
          })
          return reject(new Error('網路加載錯誤'))
        }
        return resolve(true)
      })
    }
  }
}

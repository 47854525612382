import config from 'src/app.config'
import { chain, isArray, head, last } from 'lodash'

const generateCover = ({ design, size = 480, chapters = [], isCalendar = false }) => {
  try {
    let { cover, author, title, template } = design
    const { imageUrl, pic, color, x, y } = cover
    const params = { size }
    let image = imageUrl || pic
    image && (image = image.replace('ipastbook.s3.amazonaws.com/media', 'static.ipastbook.com'))
    params.title = title

    if (isCalendar) {
      x && (params['pic[posX]'] = x)
      y && (params['pic[posY]'] = y)
      image && (params.pic = image)
    } else if (/magazine|album/.test(template)) { // PhotoBook cover
      params.subtitle = author
      image && (params.pic = image)
    } else { // DiaryBook
      let range = ''
      if (isArray(chapters) && chapters.length) {
        chapters = chapters.map(item => {
          item.year = item.month.substr(0, 4)
          return item
        })
        const years = chain(chapters).sortBy('year').map('year').sortedUniq().value()
        range = years.length > 1 ? head(years) + '~' + last(years) : head(years)
      }

      if (isColorDark(color) && config.colorfulCovers.test(template)) {
        template = template + '-white'
      }
      params.author = author
      params.bgColor = color
      image && (params.mainImg = image)
      range && (params.years = range)
    }

    const queryList = Object.keys(params).map(item => {
      return `${item}=${encodeURIComponent(params[item])}`
    })

    let url = `https://canvas.xinshu.me/generate/${template}`
    url = url + '?' + queryList.join('&')
    return url
  } catch (e) {
    console.error(e.message)
    return ''
  }
}

function isColorDark (color) {
  // extra light color as dark
  const extraDarkColorList = [
    '#434343',
    '#c44a5c',
    '#ffc73b',
    '#43a3aa',
    '#82b98d'
  ]
  if (extraDarkColorList.includes(color)) {
    return true
  }
  let r, g, b
  if (color.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

    r = color >> 16
    g = color >> 8 & 255
    b = color & 255
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  )

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return false
  } else {
    return true
  }
}

export default generateCover

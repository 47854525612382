<template>
  <div class="cover-holder" :data-src="src" :style="style" @click="onClick">
    <div class="cover-holder-inner">
      <template v-if="src">
        <i class="fa fa-spinner fa-spin centered" v-if="status === 1 && !noLoader"></i>
        <i class="fa fa-exclamation-circle centered" v-if="status === 3"></i>
        <transition name="fade">
          <div :style="imageStyle" class="image" v-show="status === 2"></div>
        </transition>
      </template>
      <slot></slot>
      <slot name="check">
        <div class="check" v-if="selected">
          <span class="check-icon"></span>
        </div>
      </slot>
    </div>
    <div class="holder" :style="{paddingBottom: `${height}%`}"/>
  </div>
</template>

<script>
import { loadImage } from '../_utils'
export default {
  name: 'cover-holder',
  props: {
    height: {
      type: Number,
      default: 100
    },
    radius: String,
    src: String,
    border: String,
    fit: Boolean,
    noLoader: Boolean,
    disabled: Boolean,
    contain: Boolean,
    selected: Boolean
  },
  data: () => ({
    status: 0,
    timer: null,
    selectable: false,
    clickable: false
  }),
  computed: {
    style () {
      const style = {}
      if (this.border !== undefined) {
        style.border = this.border || '1px solid rgba(50,50,50,.1)'
      }
      if (this.radius !== undefined) {
        style.borderRadius = this.radius || '4px'
      }
      return style
    },
    imageStyle () {
      const style = {
        backgroundSize: this.contain ? 'contain' : 'cover'
      }
      if (this.src) {
        style.backgroundImage = `url("${this.src}")`
      }
      return style
    }
  },
  watch: {
    src: 'init'
  },
  mounted () {
    this.$nextTick(() => {
      this.selectable = this.$props.selected !== undefined
      this.clickable = !!this.$listeners.click
    })

    if (!('IntersectionObserver' in window)) {
      this.init()
    } else {
      this.observer = new IntersectionObserver(entries => {
        const entry = entries[0]
        if (!entry) {
          return
        }
        // Use `intersectionRatio` because of Edge 15's
        // lack of support for `isIntersecting`.
        // See: https://github.com/w3c/IntersectionObserver/issues/211
        if (entry.isIntersecting || entry.intersectionRatio > 0) {
          this.observer.unobserve(this.$el)
          this.init()
        }
      })
      this.observer.observe(this.$el)
    }
  },
  methods: {
    onClick (e) {
      if (this.status === 3) {
        return
      }
      this.$emit('click', e)
    },
    init () {
      if (this.src) {
        clearTimeout(this.timeout)
        this.status = 0
        this.timeout = setTimeout(() => {
          this.status = 1
        }, 150)
        loadImage(this.src).then(({ w, h }) => {
          this.status = 2
          this.orientation = w > h ? 'h' : 'v'
          if (w / h > 0.9 && w / h < 1.1) {
            this.orientation = 's'
          }
        }).catch(() => {
          this.status = 3
        }).then(() => {
          clearTimeout(this.timeout)
        })
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/variables';
  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }

  .fade-enter-to, .fade-leave {
    opacity: 1 !important;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0 !important;
  }

  .cover-holder {
    position: relative;
    box-shadow: 0 0 6px rgba(0,0,0,.12);
    width: 100%;
    &.calendar {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 5%;
        right: 5%;
        background-image: url(../../static/images/placeholder/ring.png);
        background-repeat: round;
        background-size: contain;
        background-position: 40% 50%;
        margin-top: -2.5%;
        padding-bottom: 5%;
        z-index: 1;
      }
    }
    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .holder {
      pointer-events: none;
      visibility: hidden;
      display: block;
      padding-bottom: 100%;
    }
    .image {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      transition: .3s;
    }
    .centered {
      width: 1em;
      height: 1em;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      line-height: 1;
      font-size: 24px;
      color: #999;
    }
    .check {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, .3);
      z-index: 10;

      @keyframes pop {
        0% {
          transform: scale(.8);
        }
        70% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1);
        }
      }

      &-icon {
        position: absolute;
        display: block;
        right: 5px;
        bottom: 5px;
        width: 24px;
        height: 24px;
        border-radius: 100px;
        background-color: $el-color-primary;
        color: #fff;
        z-index: 12;
        animation: pop .3s ease-out;
        box-shadow: $dp-1;
        &:after {
          display: block;
          content: '';
          transform-origin: top right;
          transform: rotate(45deg);
          width: 6px;
          height: 12px;
          border-bottom: 2px solid #fff;
          border-right: 2px solid #fff;
          position: absolute;
          right: 0;
          bottom: 0;
          margin-right: 5px;
          margin-bottom: 4px;
          z-index: 15;
        }
      }
    }
  }
</style>

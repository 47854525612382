<template>
  <div class="home">
    <home-banner />
    <!-- <home-product /> -->
    <home-group />
    <home-words />
  </div>
</template>

<script>
import '../styles/layout/Home.scss'
import HomeBanner from './Home/Banner'
// import HomeProduct from './Home/Product'
import HomeGroup from './Home/Group'
import HomeWords from './Home/Words'
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    HomeBanner,
    // HomeProduct,
    HomeGroup,
    HomeWords
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'language', 'user'
    ]),
    title () {
      return this.$t('title.home')
    }
  },
  created () {
    this.checkSource()
  },
  methods: {
    // 检查首页是否来源于邀请
    checkSource () {
      let { sourceId, ref } = this.$route.query
      sourceId = sourceId || ref
      if (sourceId && !this.user.uid) {
        Cookies.set('sourceId', sourceId, { expires: 90 })
      }
    }
  }
}
</script>

<style lang="scss">
.home {
  & > section {
    padding: 2.5rem 1rem;
    position: relative;
    header {
      text-align: center;
      margin-bottom: 2.5rem;
      h2 {
        font-size: 2.25rem;
        color: #000;
        font-weight: 500;
      }
      .subtitle {
        font-size: 20px;
        color: #9b9b9b;
        margin: 1rem 0 1.5rem;
      }
    }
    h2 {
      font-size: 1.5rem;
      color: #000;
      font-weight: 500;
    }
    & > .container {
      padding: 0 2rem;
    }
  }
}
@media (max-width: 768px) {
  .home {
    &> section {
      header {
        margin-bottom: 1.75rem;
        h2 {
          font-size: 1.5rem;
        }
      }
      h2 {
        font-size: 1.15rem;
        color: #000;
        font-weight: 500;
      }
      &>.container{
        padding: 0 1rem!important;
      }
    }
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Shelf from 'src/views/Shelf/Shelf'
import Home from 'src/views/Home'
import Review from 'src/views/Preview/Review.vue'
import payment from 'src/views/Payment/'
import design from 'src/views/Design/'
import Login from 'src/views/Login.vue'
import { state } from 'src/store/'
import { isEmpty } from 'lodash'
import i18n from '../i18n'
// import Toast from '../components/common/Toast/index'

Vue.use(Router)

let routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      topFixed: true,
      public: true,
      title: 'title.home'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      hideHead: true,
      public: true,
      title: 'title.login'
    }
  },
  {
    path: '/app/shelf',
    name: 'shelf',
    component: Shelf,
    meta: {
      title: 'title.shelf'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: resolve => {
      require(['../views/products.vue'], resolve)
    },
    meta: {
      title: '產品詳情',
      public: true
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: resolve => {
      require(['../views/template.vue'], resolve)
    },
    meta: {
      title: '模板列表',
      public: true
    }
  },
  {
    path: '/setup',
    name: 'setup',
    component: resolve => {
      require(['../views/create.vue'], resolve)
    },
    meta: {
      title: '新建相片書',
      public: true
    }
  },
  {
    path: '/app/review',
    name: 'review',
    component: Review,
    meta: {
      public: true,
      title: 'title.preview',
      root: 'design',
      hideMobile: true
    }
  }
]

routes = routes
  .concat(design)
  .concat(payment)
  .concat([
    {
      path: '/app/order',
      name: 'order-status',
      component: (resolve) => {
        require(['../views/OrderList/OrderStatus.vue'], resolve)
      },
      meta: {
        title: 'title.order'
      }
    },
    {
      path: '/app/orders',
      name: 'order-list',
      component: (resolve) => {
        require(['../views/OrderList/OrderList.vue'], resolve)
      },
      meta: {
        title: 'title.orders'
      }
    },
    {
      path: '/app/pdf',
      name: 'order-pdf',
      component: (resolve) => {
        require(['../views/OrderList/OrderPdf.vue'], resolve)
      },
      meta: {
        title: 'title.pdf'
      }
    },
    {
      path: '/app/profile',
      name: 'profile',
      component: (resolve) => {
        require(['../views/Profile/Profile.vue'], resolve)
      },
      meta: {
        title: 'title.profile'
      }
    },
    {
      path: '/app/coupons',
      name: 'profile-coupons',
      component: (resolve) => {
        require(['../views/Profile/Coupons.vue'], resolve)
      },
      meta: {
        title: 'title.coupons'
      }
    },
    {
      path: '/app/invitation',
      name: 'profile-invitation',
      component: (resolve) => {
        require(['../views/Profile/Invitation.vue'], resolve)
      },
      meta: {
        title: 'title.invitation'
      }
    },
    {
      path: '/agent',
      name: 'agent',
      component: (resolve) => {
        require(['../views/Agent/index.vue'], resolve)
      },
      meta: {
        title: '我是合夥人'
      }
    },
    {
      path: '/agent/landing',
      name: 'landing-page',
      component: (resolve) => {
        require(['../views/Agent/landing.vue'], resolve)
      },
      meta: {
        title: '邀请成为合伙人',
        needRedirect: true,
        public: true
      }
    },
    {
      path: '/agent/fans',
      name: 'agent-fans',
      component: (resolve) => {
        require(['../views/Agent/fans.vue'], resolve)
      }
    },
    {
      path: '/agent/info',
      name: 'agent-info',
      component: (resolve) => {
        require(['../views/Agent/info.vue'], resolve)
      }
    },
    {
      path: '/agent/order',
      name: 'agent-order',
      component: (resolve) => {
        require(['../views/Agent/order.vue'], resolve)
      }
    },
    {
      path: '/agent/withdraw',
      name: 'agent-withdraw',
      component: (resolve) => {
        require(['../views/Agent/withdraw.vue'], resolve)
      }
    },
    {
      path: '/agent/record',
      name: 'agent-record',
      component: (resolve) => {
        require(['../views/Agent/record.vue'], resolve)
      }
    },
    {
      path: '/group/start',
      name: 'group-start',
      meta: {
        public: true,
        needRedirect: true
      },
      component: resolve => {
        require(['../views/Group/Start.vue'], resolve)
      }
    },
    {
      path: '/group/event',
      name: 'group-event',
      meta: {
        public: false,
        needRedirect: true
      },
      component: resolve => {
        require(['../views/Group/Event.vue'], resolve)
      }
    },
    {
      path: '/group/record',
      name: 'group-record',
      meta: {
        public: false
      },
      component: resolve => {
        require(['../views/Group/Record.vue'], resolve)
      }
    },
    {
      path: '/groups',
      name: 'groups',
      meta: {
        public: true,
        needRedirect: true
      },
      component: resolve => {
        require(['../views/groups.vue'], resolve)
      }
    },
    {
      path: '/testimonials',
      name: 'testimonials',
      component: (resolve) => {
        require(['../views/Testimonials/index.vue'], resolve)
      },
      meta: {
        public: true,
        title: 'title.statements'
      }
    },
    {
      path: '/not-found',
      name: 'not-found',
      component: (resolve) => {
        require(['../components/layout/404.vue'], resolve)
      },
      meta: {
        public: true,
        title: '未找到页面'
      }
    },
    {
      path: '*',
      redirect: { name: 'not-found' }
    }
  ])

const router = new Router({
  routes,
  mode: 'history',
  linkActiveClass: 'router-link-active',
  scrollBehavior (to, from, savedPosition) {
    if (to.meta.savedPosition) {
      return savedPosition // 结算页面路由跳转禁止自动滑动
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const { user, language } = state
  if (!to.meta.public && isEmpty(user)) {
    window.location.href = `${window.location.origin}/social/facebook_login`
    return
  }

  // 日语首页暂时回到日本站点
  if (language === 'jp' && to.name === 'home') {
    window.location.href = 'https://ipastbook.com/jp'
    return
  }
  next()
})

router.afterEach(route => {
  if (route.meta.title) {
    document.title = i18n.t(route.meta.title) + ' - iPastBook'
  } else {
    document.title = 'iPastBook'
  }
  if (location.port) {
    document.title = '[DEV]' + document.title
  }
  // 添加 Facebook pixel
  window.fbq('track', 'ViewContent')
})

export default router

<template>
  <header class="td" :class="{'fixed': fixed}">
    <button class="button visible-xs left" @click.stop="navShow = !navShow">
      <i class="ion-navicon-round"></i>
    </button>
    <div id="logo">
      <router-link to="/">
        <img src="../../../static/images/logo/iPastBook-logo.svg" alt="logo" height="24"/>
        <img src="../../../static/images/logo/header-slogan.png" class="hidden-xs" alt="slogan" height="24" v-if="language.includes('zh')"/>
      </router-link>
      <div class="fb-like-btn">
        <div id="fb-root"></div>
        <div class="fb-like" v-if="language === 'en'"
             data-href="https://www.facebook.com/iPastBookBot"
             data-layout="button_count"
             data-action="like"
             data-size="large"
             data-show-faces="false"
             data-share="false"
             style=""></div>
        <div class="fb-like"
             data-href="https://www.facebook.com/iPastBookBot"
             data-layout="button_count"
             data-action="like"
             data-size="large"
             data-show-faces="false"
             data-share="false"
             v-else></div>
      </div>
    </div>
    <div class="nav-list hidden-xs">
      <ul>
        <template v-if="!user.uid">
          <li>
            <router-link :to="{name: 'home'}" exact>{{$t('header.navHome')}}</router-link>
          </li>
          <!-- <li>
            <router-link to="/testimonials">{{$t('header.navStatements')}}</router-link>
          </li> -->
        </template>
        <template v-else>
          <li>
            <router-link :to="{name: 'shelf'}">{{$t('header.navShelf')}}</router-link>
          </li>
          <li>
            <router-link :to="{name: 'order-list'}">{{$t('header.navOrder')}}</router-link>
          </li>
          <!-- <li>
            <router-link to="/testimonials">{{$t('header.navStatements')}}</router-link>
          </li> -->
        </template>
      </ul>
    </div>
    <!--右边按钮-->
    <div class="user-profile text">
      <router-link :to="{name: 'profile'}" v-if="user.uid" class="profile-link">
        <span class="header-avatar" :style="{backgroundImage: 'url(' + user.avatar + ')'}">
        </span>
      </router-link>
      <template v-else>
        <button class="btn primary"  @click="setLoginShow(true)">
          {{$t('header.navStart')}}
          <i class="fa fa-caret-right" aria-hidden="true"></i>
        </button>
      </template>
    </div>
    <!--移动端顶部导航-->
    <transition name="transform">
      <nav-list v-if="navShow"></nav-list>
    </transition>
  </header>
</template>

<script>
import NavList from './NavList'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'i-header',
  props: {
    user: {
      type: Object,
      default: function () {
        return {}
      }
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  components: { NavList },
  data () {
    return {
      navShow: false,
      loginShow: false
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  created () {
  },
  mounted () {
    document.documentElement.addEventListener('click', this.handleClick, false)
  },
  beforeDestroy () {
    document.documentElement.removeEventListener('click', this.handleClick)
  },
  methods: {
    ...mapActions([
      'setLoginShow'
    ]),
    handleClick () {
      this.navShow = false
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/layout/Header.scss';
</style>

<template>
  <div class="mobile-nav-list">
    <ul class="nav-root">
      <template v-if="user.uid">
        <li>
          <router-link to="/app/shelf">{{$t('header.navShelf')}}</router-link>
        </li>
        <li>
          <router-link to="/app/orders">{{$t('header.navOrder')}}</router-link>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: '',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  created () {}
}
</script>

<style lang="scss">
@import '../../styles/layout/NavList.scss';
</style>

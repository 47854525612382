<template>
  <div class="container design" :class="{'preview-bg': $route.name === 'preview'}">
    <router-link :to="{name: 'shelf'}" class="indicator" v-if="$route.name === 'preview'">
      <img width="36" src="../../../static/images/logo/logo-transparent.svg" alt="">
      <div>
        {{$t('preview.buttonShelf')}}
      </div>
    </router-link>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'design',
  data () {
    return {}
  },
  computed: {
    ...mapGetters([
      'showStep'
    ])
  },
  created () {},
  mounted () {
  },
  beforeRouteLeave (to, from, next) {
    next()
  }
}
</script>

<style lang="scss">

.design {
  position: relative;
  label {
    font-size: 14px;
  }
  &.preview-bg {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    .previewer {
      flex: 1;
    }
  }
  @media (min-width: 768px) {
    .step-wrapper {
      padding: 1rem 0 0;
      margin-bottom: 1rem;
      .transparent-bg {
        background-color: rgba(255, 255, 255, .35);
        border-radius: 8px;
        box-shadow: inset 0 4px 4px rgba(0, 0, 0,.15), inset 0 -2px 10px rgba(255, 255, 255,.35);
      }
    }
    .indicator {
      position: fixed;
      top: 0;
      left: 1rem;
      display: block;
      width: 60px;
      background-color: $el-color-primary;
      overflow: hidden;
      color: white;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
      padding: 8px 6px 6px;
      z-index: 3;
      div {
        margin-top: 4px;
      }
    }
    .step .tab {
      background-color: #f2e4d0;
    }
  }
  @media (max-width: 767px) {
    padding-top: 80px;
    &.preview-bg {
      width: 100%;
      margin: 0;
    }
    .indicator {
      display: none;
    }
  }
}
</style>

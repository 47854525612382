import Vue from 'vue'
import ModalComponent from './Modal.vue'

const IldConfirm = Vue.extend(ModalComponent)
const confirmPool = []

const getAnInstance = () => {
  if (confirmPool.length > 0) {
    const instance = confirmPool.shift()
    return instance
  }
  return new IldConfirm({
    el: document.createElement('div')
  })
}

const returnAnInstance = instance => {
  if (instance) {
    confirmPool.push(instance)
  }
}

const removeDom = event => {
  if (event.target.parentNode) {
    event.target.parentNode.removeChild(event.target)
  }
}

IldConfirm.prototype.close = function () {
  this.show = false
  this.handleAfterLeave = () => {
    removeDom({ target: this.$el })
  }
  returnAnInstance(this)
}

/*
 * options:
 *   duration: 持续时间，默认3000，0为不消失
 *   message: toast提示信息
 *   position: 显示位置（middle(默认), top(Message), bottom）
 *   className: class名
 *   icon:
 *     type: icon种类（暂时有material，ionicon两种）
 *     content: icon内容
 *   closeable: 是否可以点击mask关闭
 *   clickable: 是否可以点击到，false为穿透
 * options为string类型时则视为message
 * */

/**
 * @param options
 * @example
 *
 * this.$confirm({
 *   title: '确定删除吗',
 *   okText: '删除',
 *   cancelText: '不删除',
 *   onOk (that) {console.log('删除了'); that.close()},
 *   onCancel (that) {console.log('取消了'); that.close()},
 *   okClass: 'text-red'
 * })
 *
 * @example
 *
 * Toast('此处为提示内容')
 */

const Confirm = (options = {}) => {
  const instance = getAnInstance()

  const defaultFunc = function () {
    instance.close()
  }
  instance.show = true
  instance.title = options.title || ' '
  instance.cancel = typeof options.cancel !== 'undefined' ? options.cancel : true
  instance.dialog = true
  instance.okText = options.okText ? options.okText : ''
  instance.cancelText = options.cancelText ? options.cancelText : ''
  instance.onOk = options.onOk ? options.onOk : defaultFunc
  instance.onCancel = options.onCancel ? options.onCancel : defaultFunc
  instance.okClass = options.okClass || ' '
  instance.cancelClass = options.cancelClass || ' '
  instance.$on('close', instance.close)
  document.body.appendChild(instance.$el)
  return instance
}

export default Confirm
